import React, { useContext, useRef } from 'react';
import AuthContext from '../context/auth-context';
import ModalDialog from '../components/ModalDialog/ModalDialog';
import Copyright from '../components/Copyright/Copyright';
import Contact from '../components/Contact/Contact';
import About from '../components/About/About';
import GalleryEvents from '../components/GalleryEvents/GalleryEvents';
import Header from '../components/Header/Header';
import Slider from '../components/Slider/Slider';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function HomePage({ token }) {
    const context = useContext(AuthContext);
    const [isShow, invokeModal] = React.useState(false);
    const [state, setState] = React.useState({
        creating: false,
        events: [],
        outputType: 'list',
    });

    const form = useRef();

    const initModal = () => {
        return invokeModal(!false);
    };
    const closeModal = () => {
        return invokeModal(false);
    };

    const successNotify = () =>
        toast.success('Booking Request Sent', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    const failNotify = () =>
        toast.error('Booking was Unsuccessful', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });

    const getDateAndTime = dateConvert => {
        const dateStr = dateConvert;
        const date = new Date(dateStr);

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const hours = date.getHours();
        const minutes = date.getMinutes();

        const dateFormatted = `${day}-${month}-${year}`;
        const timeFormatted = `${hours}:${minutes}`;

        return { dateFormatted, timeFormatted };
    };

    const onSubmit = (name, email, date, description, people) => {
        if (
            name.trim().length === 0 ||
            date.trim().length === 0 ||
            description.trim().length === 0 ||
            email.trim().length === 0
        ) {
            return;
        }

        const event = { name, email, date, description, people };

        const requestBody = {
            query: `
                mutation CreateEvent($name: String!, $description: String!, $email: String!, $date: String!, $people: String!) {
                    createEvent(name: $name, description: $description, email: $email, date: $date, people: $people) {
                    id
                    name
                    description
                    email
                    date
                    people
                    }
                }
            `,
            variables: {
                name: name,
                description: description,
                email: email,
                date: date,
                people: people,
            },
        };

        fetch('https://mighty-earth-66792.herokuapp.com/graphql', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    failNotify();
                    throw new Error('Failed');
                }
                return res.json();
            })
            .then(resData => {
                setState(prevState => {
                    const updatedEvents = [...prevState.events];
                    updatedEvents.push({
                        id: resData.data.createEvent.id,
                        name: resData.data.createEvent.name,
                        description: resData.data.createEvent.description,
                        email: resData.data.createEvent.email,
                        date: resData.data.createEvent.date,
                        people: resData.data.createEvent.people,
                    });

                    const newEvent = {
                        id: resData.data.createEvent.id,
                        name: resData.data.createEvent.name,
                        description: resData.data.createEvent.description,
                        email: resData.data.createEvent.email,
                        date: resData.data.createEvent.date,
                        people: resData.data.createEvent.people,
                        convertedDate: getDateAndTime(
                            resData.data.createEvent.date,
                        ).dateFormatted,
                        time: getDateAndTime(resData.data.createEvent.date)
                            .timeFormatted,
                    };

                    axios
                        .post(
                            'https://mighty-earth-66792.herokuapp.com/send-email',
                            {
                                newEvent,
                            },
                        )
                        .then(res => {
                            console.log(res.data);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                    successNotify();
                    return { events: updatedEvents };
                });
            })
            .catch(err => {
                console.log(err);
                return;
            });
    };

    return (
        <div>
            <div className="all">
                <Slider />
                <Header token={token} />
                <About />
                <GalleryEvents initModal={initModal} />
                <ModalDialog
                    initModel={initModal}
                    closeModal={closeModal}
                    isShow={isShow}
                    onSubmit={onSubmit}
                    formRef={form}
                />
                <div id="section-contact" className="footer-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Contact />
                                <Copyright />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
