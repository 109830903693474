import React, { useEffect, useState } from 'react';
import { FastAverageColor } from 'fast-average-color';
import LazyLoad from 'react-lazyload';

import './About.css';

const fac = new FastAverageColor();

const About = () => {
    const [focusedItem, setFocusedItem] = useState(null);

    useEffect(() => {
        const accordionItems = document.querySelectorAll('.accordion__item');

        accordionItems.forEach(el => {
            el.addEventListener('mouseover', () => {
                if (focusedItem) {
                    focusedItem.classList.remove('accordion__item_focused');
                }
                el.classList.add('accordion__item_focused');
                setFocusedItem(el);
            });

            const imageEl = el.querySelector('img');
            fac.getColorAsync(imageEl.src, { algorithm: 'simple' })
                .then(color => {
                    imageEl.style.setProperty('--glow-color', color.hex);
                })
                .catch(e => {
                    console.error(e);
                });
        });

        let touchstartX = 0;
        let touchendX = 0;

        const handleTouchStart = e => {
            touchstartX = e.changedTouches[0].screenX;
        };

        const handleTouchEnd = e => {
            touchendX = e.changedTouches[0].screenX;
            checkDirection();
        };

        const checkDirection = () => {
            const diff = Math.abs(touchendX - touchstartX);

            if (focusedItem) {
                if (
                    touchendX < touchstartX &&
                    diff >= 50 &&
                    focusedItem.nextElementSibling !== null
                ) {
                    focusedItem.classList.remove('accordion__item_focused');
                    focusedItem.nextElementSibling.classList.add(
                        'accordion__item_focused',
                    );
                    setFocusedItem(focusedItem.nextElementSibling);
                }

                if (
                    touchendX > touchstartX &&
                    diff >= 50 &&
                    focusedItem.previousElementSibling !== null
                ) {
                    focusedItem.classList.remove('accordion__item_focused');
                    focusedItem.previousElementSibling.classList.add(
                        'accordion__item_focused',
                    );
                    setFocusedItem(focusedItem.previousElementSibling);
                }
            }
        };

        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchend', handleTouchEnd);
        return () => {
            accordionItems.forEach(el => {
                el.removeEventListener('mouseover', () => {
                    if (focusedItem) {
                        focusedItem.classList.remove('accordion__item_focused');
                    }
                    el.classList.add('accordion__item_focused');
                    setFocusedItem(el);
                });
            });

            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [focusedItem]);

    return (
        <div id="section-about" className="section section-about">
            <div className="container about-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="aboutx clearfix">
                            <article className="about text-center">
                                <div className="container-about">
                                    <ul className="accordion">
                                        <li className="accordion__item accordion__item_focused">
                                            <img
                                                className="about-image"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/shoot-images/min/Bar-1-min.webp'
                                                }
                                                alt="modern art"
                                                width="250"
                                                height="350"
                                            />
                                        </li>
                                        <li className="accordion__item">
                                            <img
                                                className="about-image"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/shoot-images/min/Shelf-1-min.webp'
                                                }
                                                alt="modern art"
                                                width="250"
                                                height="350"
                                            />
                                        </li>
                                        <li className="accordion__item">
                                            <img
                                                className="about-image"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/shoot-images/min/Bar-3-min.webp'
                                                }
                                                alt="modern art"
                                                width="250"
                                                height="350"
                                            />
                                        </li>
                                        <li className="accordion__item">
                                            <img
                                                className="about-image"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/shoot-images/min/Bar-2-min.webp'
                                                }
                                                alt="modern art"
                                                width="250"
                                                height="350"
                                            />
                                        </li>
                                        <li className="accordion__item">
                                            <img
                                                className="about-image"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/img/shoot-images/min/Bar-4-min.webp'
                                                }
                                                alt="modern art"
                                                width="250"
                                                height="350"
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
