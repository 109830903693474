import React from 'react';

const Copyright = () => (
    <div className="section-copyright">
        <a href="/#" className="icon">
            <i className="fa fa-beer"></i>
        </a>
        <strong>The Attic Lounge 2023</strong>. Developed by{' '}
        <span className="author">Melissa Dennis</span>.
    </div>
);

export default Copyright;
