import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

const ModalDialog = ({ closeModal, isShow, onSubmit, formRef }) => {
    const [date, setDate] = useState('2023-03-28');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [people, setPeople] = useState(0);

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [dateError, setDateError] = useState('');
    const [peopleError, setPeopleError] = useState('');

    const validateInputs = () => {
        let isValid = true;
        if (name.trim() === '') {
            setNameError('Name is required');
            isValid = false;
        } else {
            setNameError('');
        }
        if (email.trim() === '') {
            setEmailError('Email is required');
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError('Invalid email format');
            isValid = false;
        } else {
            setEmailError('');
        }
        if (description.trim() === '') {
            setDescriptionError('Description is required');
            isValid = false;
        } else {
            setDescriptionError('');
        }
        if (date.trim() === '') {
            setDateError('Date is required');
            isValid = false;
        } else {
            setDateError('');
        }
        if (people === 0) {
            setPeopleError('Amount of people is required');
            isValid = false;
        } else {
            setPeopleError('');
        }
        return isValid;
    };

    const handleSubmit = () => {
        if (validateInputs()) {
            onSubmit(name, email, date, description, people);
            closeModal();
        }
    };

    return (
        <>
            <Modal className="" animation={false} show={isShow}>
                <Modal.Header closeButton onClick={closeModal}>
                    <Modal.Title>Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form ref={formRef}>
                        {/* <div className="row">
                            <div className="col-md-6">
                                <div className="form-group"> */}

                        <label
                            className="modal-dialog-label"
                            htmlFor="contact_name"
                        >
                            Name
                        </label>
                        <br />

                        <input
                            type="text"
                            value={name}
                            className="form-control"
                            id="contact_name"
                            placeholder="John Wick"
                            onChange={e => setName(e.target.value)}
                            required
                        />
                        {nameError && (
                            <div className="error-message">{nameError}</div>
                        )}
                        {/* </div>
                            </div> */}
                        {/* <div className="col-md-6">
                                <div className="form-group has-feedback"> */}
                        <label
                            className="modal-dialog-label"
                            htmlFor="contact_name"
                        >
                            Email
                        </label>
                        <br />
                        <input
                            type="email"
                            value={email}
                            className="form-control"
                            id="contact_email"
                            placeholder="john@gmail.com"
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                        {emailError && (
                            <div className="error-message">{emailError}</div>
                        )}
                        {/* </div>
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="form-group"> */}
                        <label
                            className="modal-dialog-label"
                            htmlFor="contact_date"
                        >
                            Date of Event
                        </label>
                        <br />
                        <Form.Control
                            value={date}
                            type="datetime-local"
                            name="doj"
                            onChange={e => {
                                setDate(e.target.value);
                            }}
                        />
                        {dateError && <div className="error">{dateError}</div>}
                        {/* </div>
                                <div className="form-group"> */}
                        <label
                            className="modal-dialog-label"
                            htmlFor="contact_name"
                        >
                            Number of people
                        </label>
                        <br />
                        <Form.Control
                            value={people}
                            type="number"
                            name="people"
                            onChange={e => {
                                setPeople(e.target.value);
                            }}
                        />
                        {peopleError && (
                            <div className="error">{peopleError}</div>
                        )}
                        {/* </div>
                                <div className="form-group form-group2"> */}
                        <label
                            className="modal-dialog-label"
                            htmlFor="contact_description"
                        >
                            Event Description
                        </label>
                        <br />
                        <textarea
                            value={description}
                            id="contact_message"
                            className="form-control"
                            rows="3"
                            placeholder="Please enter a name or short description of your event"
                            onChange={e => {
                                setDescription(e.target.value);
                            }}
                            required
                        />
                        {descriptionError && (
                            <div className="error">{descriptionError}</div>
                        )}
                        {/* </div>
                            </div>
                        </div> */}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        className="btn-book-send booking-button"
                        onClick={handleSubmit}
                    >
                        Book Now
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ModalDialog;
