import React, { useState, useRef } from 'react';
import './EventItem.css';
import DetailDialog from '../../../ModalDialog/DetailDialog';

function EventItem(props) {
    const [isShow, invokeModal] = useState(false);

    const detailForm = useRef();

    const closeModal = () => {
        return invokeModal(false);
    };

    const initModal = () => {
        return invokeModal(!false);
    };

    return (
        <li key={props.id} className="post-item clearfix events__list-item">
            <div className="event-item card">
                <div className="event-item-info">
                    <h3 className="item-title">{props.name}</h3>
                    <div className="item-meta">
                        <i className="fa fa-calendar-o"></i>&nbsp;
                        {new Date(props.date).toLocaleDateString()} &nbsp;
                        <i className="fa fa-user"></i>&nbsp; {props.email}&nbsp;
                    </div>
                </div>
                <div className="event-item-button">
                    {!props.event.confirm && (
                        <button className="no-style-button" onClick={initModal}>
                            {' '}
                            <i className="fa fa-edit"></i>
                        </button>
                    )}
                    <DetailDialog
                        event={props.event}
                        isShow={isShow}
                        closeModal={closeModal}
                        onSubmit={props.onSubmit}
                        formRef={detailForm}
                    />
                </div>
            </div>
        </li>
    );
}
export default EventItem;
