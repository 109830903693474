import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

const DetailDialog = ({ closeModal, isShow, formRef, event }) => {
    const [price, setPrice] = useState(0);
    const [confirm, setConfirm] = useState(false);

    const [priceError, setPriceError] = useState('');
    const [confirmError, setConfirmError] = useState('');

    const dateStr = event.date;
    const date = new Date(dateStr);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const dateFormatted = `${day}-${month}-${year}`;
    const timeFormatted = `${hours}:${minutes}`;
    const onSubmit = (id, price, confirm) => {
        const requestBody = {
            query: `
                mutation UpdateEvent($id: ID!, $price: Float!, $confirm: Boolean!) {
                    updateEvent(id: $id, price: $price, confirm: $confirm) {
                    id
                    price
                    confirm
                    }
                }
            `,
            variables: {
                id: id,
                price: price ? parseFloat(price) : null,
                confirm: confirm ? Boolean(confirm) : null,
            },
        };

        fetch('https://mighty-earth-66792.herokuapp.com/graphql', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Failed');
                }
                return res.json();
            })
            .then(resData => {
                console.log('GraphQL Results', resData);
            })
            .catch(err => {
                console.log(err);
                return;
            });
    };

    const handleSubmit = () => {
        onSubmit(event.id, price, confirm);
        const email = event.email;
        if (confirm) {
            const event = { price: price, email: email };
            console.log('Object Before API Call', event);
            axios
                .post(
                    'https://mighty-earth-66792.herokuapp.com/confirm-booking-email',
                    { event },
                )
                .then(res => {
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err);
                });
            //successNotify();
        }
        closeModal();
    };

    return (
        <>
            <Modal animation={false} show={isShow}>
                <Modal.Header closeButton onClick={closeModal}>
                    <Modal.Title>Booking Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form ref={formRef}>
                        {/* <div className="row">
                            <div className="col-md-6">
                                <div className="form-group"> */}

                        <label
                            className="modal-dialog-label"
                            htmlFor="contact_name"
                        >
                            Name
                        </label>
                        <br />
                        <input
                            type="text"
                            className="form-control"
                            id="contact_name"
                            value={event.name}
                            readOnly={true}
                        />
                        {/* </div>
                            </div> */}
                        {/* <div className="col-md-6">
                                <div className="form-group has-feedback"> */}
                        <label
                            className="modal-dialog-label"
                            htmlFor="contact_name"
                        >
                            Email
                        </label>
                        <br />
                        <input
                            type="email"
                            value={event.email}
                            className="form-control"
                            id="contact_email"
                            readOnly={true}
                        />
                        {/* </div>
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="form-group"> */}
                        <label className="modal-dialog-label" htmlFor="date">
                            Date of Event
                        </label>
                        <br />
                        <input
                            value={dateFormatted}
                            type="text"
                            id="date"
                            name="doj"
                            className="form-control"
                            readOnly={true}
                        />
                        {/* </div>
                                <div className="form-group"> */}
                        <label className="modal-dialog-label" htmlFor="time">
                            Time
                        </label>
                        <br />
                        <input
                            value={timeFormatted}
                            type="text"
                            id="time"
                            name="doj"
                            className="form-control"
                            readOnly={true}
                        />
                        <label className="modal-dialog-label" htmlFor="people">
                            Number of people
                        </label>
                        <br />
                        <input
                            value={event.people}
                            type="number"
                            id="people"
                            name="people"
                            className="form-control"
                            readOnly={true}
                        />
                        {/* </div>
                                <div className="form-group form-group2"> */}
                        <label
                            className="modal-dialog-label"
                            htmlFor="contact_message"
                        >
                            Event Description
                        </label>
                        <br />
                        <textarea
                            value={event.description}
                            id="contact_message"
                            className="form-control"
                            rows="3"
                            readOnly={true}
                        />
                        {/* </div>
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="form-group"> */}
                        <label className="modal-dialog-label" htmlFor="price">
                            Price
                        </label>
                        <br />
                        <input
                            type="number"
                            value={price}
                            className="form-control"
                            id="price"
                            placeholder="Price"
                            onChange={e => setPrice(e.target.value)}
                            required
                        />
                        {priceError && (
                            <div className="error-message">{priceError}</div>
                        )}
                        {/* </div>
                            </div> */}
                        {/* <div className="col-md-12">
                                <div className="form-group"> */}
                        <div className="confirm-container">
                            <label
                                className="modal-dialog-label confirm-label"
                                htmlFor="confirm"
                            >
                                Confirm Event
                            </label>
                            <br />
                            <input
                                type="checkbox"
                                value={confirm}
                                id="confirm"
                                onChange={e => setConfirm(e.target.value)}
                                required
                            />
                        </div>
                        {confirmError && (
                            <div className="error-message">{confirmError}</div>
                        )}
                        {/* </div>
                            </div>
                        </div> */}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        className="btn-book-send booking-button"
                        onClick={handleSubmit}
                    >
                        Save changes{' '}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default DetailDialog;
