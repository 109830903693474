import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/auth-context';

const Sidebar = () => {
    const { token, login, logout } = useContext(AuthContext);

    useEffect(() => {
        if (token) {
        }
    }, [token, login, logout]);

    return (
        <div className="mt-sidebar">
            <span className="close-mt-sidebar">
                <i className="fa fa-caret-left"></i> Close Sidebar
            </span>
            <nav className="main-nav"></nav>
        </div>
    );
};

export default Sidebar;
