import React, { useState } from 'react';
import axios from 'axios';

const Contact = () => {
    const [subject, setSubject] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const submitForm = () => {
        const contact = {
            name,
            email,
            message,
            subject,
        };

        axios
            .post('https://mighty-earth-66792.herokuapp.com/contact-email', {
                contact,
            })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <div className="section section-contact">
            <header className="section-header text-center">
                <h3 className="section-title">
                    Get in <strong>Touch</strong>
                </h3>
                <p>
                    <i className="fa fa-angle-down"></i>
                </p>
            </header>

            <div className="row">
                <div className="col-md-10 col-md-offset-1">
                    <div className="social-network text-center">
                        <a
                            href="https://www.facebook.com/theatticbvi"
                            className="icon icon-facebook"
                        >
                            <i className="fa fa-facebook"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-md-offset-1">
                    <div className="contact-info">
                        <ul>
                            <li>
                                Pier Park, Road Town
                                <br />
                                Tortola, BVI
                            </li>
                            <li>
                                contact.atticlounge@gmail.com
                                <br />
                                <strong>+1 284 544 9969</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <form id="contact_form" className="contact-form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input
                                        value={name}
                                        type="text"
                                        className="form-control"
                                        id="contact_name"
                                        placeholder="Name"
                                        onChange={e => setName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group has-feedback">
                                    <input
                                        value={email}
                                        type="email"
                                        className="form-control"
                                        id="contact_email"
                                        placeholder="Email"
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input
                                        value={subject}
                                        type="text"
                                        className="form-control"
                                        id="contact_subject"
                                        placeholder="Subject"
                                        onChange={e =>
                                            setSubject(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <div className="form-group form-group2">
                                    <textarea
                                        value={message}
                                        id="contact_message"
                                        className="form-control"
                                        rows="3"
                                        onChange={e =>
                                            setMessage(e.target.value)
                                        }
                                        placeholder="Message"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        id="contact_send"
                                        type="submit"
                                        onClick={submitForm}
                                        className="btn btn-send ladda-button"
                                        data-style="slide-up"
                                    >
                                        Send Us Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default Contact;
