import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../context/auth-context';
import EventList from '../components/Events/EventList/EventList';
import Slider from '../components/Slider/Slider';
import Header from '../components/Header/Header';

function BookingsPage({ token }) {
    const { userId } = useContext(AuthContext);

    const [state, setState] = useState({
        creating: false,
        events: [],
        isLoading: false,
        selectedEvent: null,
    });

    const [active, isActive] = useState(true);

    useEffect(() => {
        fetchEvents();
    }, [state.events, token]);

    const fetchEvents = () => {
        setState({
            ...state,
            isLoading: true,
        });
        const requestBody = {
            query: `
                query FetchEvents($order: String) {
                    events(order: $order) {
                        id
                        name
                        description
                        people
                        date
                        email
                        confirm
                        price
                    }
                }
            `,
            variables: {
                order: 'DESC',
            },
        };

        fetch('https://mighty-earth-66792.herokuapp.com/graphql', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Failed');
                }
                return res.json();
            })
            .then(resData => {
                const events = resData.data.events;
                if (active) {
                    setState({
                        ...state,
                        events,
                        isLoading: false,
                    });
                }
            })
            .catch(err => {
                console.log(err);
                if (active) {
                    setState({
                        ...state,
                        isLoading: false,
                    });
                }
            });
    };

    const showDetailHandler = eventId => {
        setState(prevState => {
            const selectedEvent = prevState.events.find(e => e.id === eventId);
            return { selectedEvent: selectedEvent };
        });
    };

    const onSubmit = () => {};

    return (
        <React.Fragment>
            <Slider />
            <Header token={token} />
            <div className="section section-events">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="section-title">Bookings</h3>
                            <EventList
                                events={state.events}
                                authUserId={userId}
                                onViewDetail={showDetailHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BookingsPage;
