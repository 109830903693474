import React from 'react';

const Slider = () => (
    <div className="slider-wrapper fullscreen">
        <div className="slogo">
            <a href="/#">
                <img
                    src={
                        process.env.PUBLIC_URL +
                        'img/updated-logo-images/min/Attic8x11wht-min.png'
                    }
                    alt="The Attic Logo"
                    className="logo"
                />
            </a>
        </div>
        <div className="slide_bg" data-stellar-background-ratio="0.3">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div
                            id="inner-slide"
                            className="inner-slide owl-carousel"
                        >
                            <div className="transparent-box">
                                <div className="group-text">
                                    <h2>
                                        The.<strong>Attic</strong>
                                        .Lounge
                                    </h2>
                                </div>
                            </div>
                            <div className="group-text">
                                <h2>
                                    <strong>Book Your Place</strong>
                                </h2>
                            </div>
                            <div className="group-text">
                                <h3>
                                    <strong>
                                        Meetings.Functions.Parties...
                                        <br />
                                        It's Up to You
                                    </strong>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="/#header-wrapper" className="scroll-down">
            &nbsp;
        </a>
    </div>
);

export default Slider;
