import React, { useState } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import AuthPage from './pages/Auth';
import BookingsPage from './pages/Bookings';
import AuthContext from './context/auth-context';
import HomePage from './pages/Home';
import { ToastContainer } from 'react-toastify';
import './App.css';

const App = () => {
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);

    const login = (token, userId, tokenExpiration) => {
        setToken(token);
        setUserId(userId);
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
    };

    const logout = () => {
        setToken(null);
        setUserId(null);
    };

    return (
        <BrowserRouter>
            <React.Fragment>
                <AuthContext.Provider
                    value={{
                        token: token,
                        userId: userId,
                        login: login,
                        logout: logout,
                    }}
                >
                    <ToastContainer theme="light" />

                    <main className="main-content">
                        <Switch>
                            {token && <Redirect from="/login" to="/" exact />}
                            {!token && (
                                <Route path="/login" component={AuthPage} />
                            )}
                            <Route
                                path="/events"
                                render={props => (
                                    <BookingsPage {...props} token={token} />
                                )}
                            />
                            <Route
                                path="/"
                                render={props => (
                                    <HomePage {...props} token={token} />
                                )}
                            />
                            {token && (
                                <Route
                                    path="/bookings"
                                    component={BookingsPage}
                                />
                            )}
                            {!token && <Redirect to="/login" exact />}
                        </Switch>
                    </main>
                </AuthContext.Provider>
            </React.Fragment>
        </BrowserRouter>
    );
};

export default App;
