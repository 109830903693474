import React from 'react';

const GalleryEvents = ({ initModal }) => (
    <div id="section-events" className="section section-portfolio">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <header className="section-header text-center">
                        <h3 className="section-title">
                            Our <strong>Events</strong>
                        </h3>
                    </header>
                </div>
            </div>
        </div>

        <div className="portfolio">
            <ul className="portfilio-grid fullwidth clearfix">
                <li className="mix events">
                    <div className="item">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/event-images/GRIMART.jpeg'
                            }
                            alt=""
                            className="img"
                        />
                        <div className="info">
                            <div className="icon"></div>
                            <h3 className="title">Upcoming Events</h3>
                        </div>
                    </div>
                </li>
                <li className="mix events">
                    <div className="item">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/event-images/Upcoming.jpeg'
                            }
                            alt=""
                            className="img"
                        />
                        <div className="info">
                            <div className="icon"></div>
                            <h3 className="title">Upcoming Events</h3>
                        </div>
                    </div>
                </li>
                <li className="mix events">
                    <div className="item">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/event-images/SaturdayNightParty.jpeg'
                            }
                            alt=""
                            className="img"
                        />
                        <div className="info">
                            <div className="icon"></div>
                            <h3 className="title">Saturday Night Party</h3>
                        </div>
                    </div>
                </li>
                <li className="mix events">
                    <div className="item">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/event-images/Cocktails.jpg'
                            }
                            alt=""
                            className="img"
                        />
                        <div className="info">
                            <div className="icon"></div>
                            <h3 className="title">Cocktails & Canapes</h3>
                        </div>
                    </div>
                </li>
                <li className="mix events">
                    <div className="item">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/event-images/Xmas-Party.jpg'
                            }
                            alt=""
                            className="img"
                        />
                        <div className="info">
                            <div className="icon"></div>
                            <h3 className="title">XMas Party</h3>
                        </div>
                    </div>
                </li>
                {/* <li className="mix events">
                    <div className="item">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img//event-images/St-Patricks-Party.jpg'
                            }
                            alt=""
                            className="img"
                        />
                        <div className="info">
                            <div className="icon"></div>
                            <h3 className="title">St Patricks Party</h3>
                        </div>
                    </div>
                </li> */}
                {/* <li className="mix events">
                    <div className="item">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/event-images/New-Years-Party.jpg'
                            }
                            alt="New Years Party"
                            className="img"
                        />
                        <div className="info">
                            <div className="icon"></div>
                            <h3 className="title">New Years Party</h3>
                        </div>
                    </div>
                </li> */}
            </ul>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="call-to-action text-center">
                        Do you have an event? &nbsp;
                        <button
                            className="btn btn-calltoaction"
                            onClick={initModal}
                        >
                            Book now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default GalleryEvents;
