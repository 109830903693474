// import React from 'react';
// import './EventList.css';

// import EventItem from './EventItem/EventItem';

// const eventList = props => {
//     const requestedEvents = [];
//     const confirmedEvents = [];
//     const events = props.events.map(event => {
//         return (
//             <EventItem
//                 event={event}
//                 key={event.id}
//                 eventId={event.id}
//                 name={event.name}
//                 email={event.email}
//                 date={event.date}
//                 description={event.description}
//                 onDetail={props.onViewDetail}
//                 initModal={props.initModal}
//                 onSubmit={props.onSubmit}
//             />
//         );
//     });
//     return <ul className="list-posts event__list">{events}</ul>;
// };

// export default eventList;
import React, { useState } from 'react';
import './EventList.css';

import EventItem from './EventItem/EventItem';
import { Tab, Tabs, Pagination } from 'react-bootstrap';

const EventList = props => {
    const [requestedPage, setRequestedPage] = useState(1);
    const [confirmedPage, setConfirmedPage] = useState(1);
    const pageSize = 5;

    // Filter events into requested and confirmed arrays
    const requestedEvents = props.events.filter(event => !event.confirm);
    const confirmedEvents = props.events.filter(event => event.confirm);

    // Slice the events based on the page number and page size
    // const requestedEventsPerPage = requestedEvents.slice(
    //     (requestedPage - 1) * pageSize,
    //     requestedPage * pageSize,
    // );
    // const confirmedEventsPerPage = confirmedEvents.slice(
    //     (confirmedPage - 1) * pageSize,
    //     confirmedPage * pageSize,
    // );

    const requestedEventsList = requestedEvents.map(event => (
        <EventItem
            event={event}
            key={event.id}
            eventId={event.id}
            name={event.name}
            email={event.email}
            date={event.date}
            description={event.description}
            people={event.people}
            onDetail={props.onViewDetail}
            initModal={props.initModal}
            onSubmit={props.onSubmit}
        />
    ));

    const confirmedEventsList = confirmedEvents.map(event => (
        <EventItem
            event={event}
            key={event.id}
            eventId={event.id}
            name={event.name}
            email={event.email}
            date={event.date}
            description={event.description}
            people={event.people}
            onDetail={props.onViewDetail}
            initModal={props.initModal}
            onSubmit={props.onSubmit}
        />
    ));

    const requestedPageCount = Math.ceil(requestedEvents.length / pageSize);
    const confirmedPageCount = Math.ceil(confirmedEvents.length / pageSize);

    return (
        <div className="event__list">
            <Tabs
                transition={false}
                defaultActiveKey="requested"
                id="event-tabs"
            >
                <Tab
                    transition={false}
                    eventKey="requested"
                    title="Requested Events"
                >
                    <ul className="list-posts">{requestedEventsList}</ul>
                    <Pagination
                        className="mt-3"
                        size="lg"
                        active={requestedPage}
                        items={requestedPageCount}
                        //   boundaryLinks
                        //  maxButtons={5}
                        onSelect={page => setRequestedPage(page)}
                    />
                </Tab>
                <Tab eventKey="confirmed" title="Confirmed Events">
                    <ul className="list-posts">{confirmedEventsList}</ul>
                    <Pagination
                        className="mt-3"
                        size="lg"
                        active={confirmedPage}
                        items={confirmedPageCount}
                        //    boundaryLinks
                        // maxButtons={5}
                        onSelect={page => setConfirmedPage(page)}
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

export default EventList;
