import React, { useState, createRef, useContext } from 'react';

import './Auth.css';
import AuthContext from '../context/auth-context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';

export default function AuthPage() {
    const [state, setState] = useState({
        isLogin: true,
    });

    const context = useContext(AuthContext);
    const successNotify = () =>
        toast.success('Logged in Successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    const failNotify = () =>
        toast.error("You're credentials are incorrect. Please try again", {
            position: toast.POSITION.BOTTOM_RIGHT,
        });

    const emailEl = createRef();
    const passwordEl = createRef();

    const switchModeHandler = () => {
        setState(prevState => {
            return { isLogin: !prevState.isLogin };
        });
    };

    const submitHandler = event => {
        event.preventDefault();
        const email = emailEl.current.value;
        const password = passwordEl.current.value;

        if (email.trim().length === 0 || password.trim().length === 0) {
            return;
        }

        let requestBody = {
            query: `
                query Login($email: String!, $password: String!) {
                    login(email: $email, password: $password) {
                        userId
                        token
                        tokenExpiration
                    }
                }
            `,
            variables: {
                email: email,
                password: password,
            },
        };

        if (!state.isLogin) {
            requestBody = {
                query: `
                    mutation CreateUser($email: String!, $password: String!) {
                        createUser(email: $email, password: $password) {
                            id
                            email
                        }
                    }
                `,
                variables: {
                    email: email,
                    password: password,
                },
            };
        }

        fetch('https://mighty-earth-66792.herokuapp.com/graphql', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    failNotify();
                    throw new Error('Failed');
                }
                return res.json();
            })
            .then(resData => {
                if (resData.data.login.token) {
                    context.login(
                        resData.data.login.token,
                        resData.data.login.userId,
                        resData.data.login.tokenExpiration,
                    );
                    successNotify();
                    localStorage.setItem('token', resData.data.login.token); // Store the token in local storage
                }
            })
            .catch(err => {
                failNotify();
                console.log(err);
            });

        Redirect('/');
    };

    return (
        <div className="section section-auth">
            <img
                src={
                    process.env.PUBLIC_URL +
                    'img/updated-logo-images/Attic8x11blk.png'
                }
                alt="The Attic Logo"
                className="header-image"
            />
            <form className="auth-form contact-form" onSubmit={submitHandler}>
                <div className="row">
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            placeholder="email"
                            ref={emailEl}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <input
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            id="password"
                            ref={passwordEl}
                            required
                        />
                    </div>
                </div>
                <div className="form-actions">
                    <button className="btn btn-send ladda-button" type="submit">
                        Submit
                    </button>
                    {/* <button
                        className="btn btn-x ladda-button"
                        type="button"
                        onClick={switchModeHandler}
                    >
                        {state.isLogin ? 'Register' : 'Login'}
                    </button> */}
                </div>
            </form>
        </div>
    );
}
