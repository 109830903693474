import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/auth-context';
import { NavLink, useHistory } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

function Header() {
    const history = useHistory();
    const { token, login, logout } = useContext(AuthContext);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            login(storedToken);
        } else {
            history.push('/');
        }
    }, [history, login]);

    const handleLogout = () => {
        logout();
        localStorage.removeItem('token');
        history.push('/');
    };

    return (
        <div id="header-wrapper" className="header-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Sidebar />
                        <header className="header-new" id="header">
                            <div className="site">
                                <h2>
                                    <a href="/#">
                                        The<strong>Attic</strong>Lounge
                                    </a>
                                </h2>
                            </div>
                            <nav id="main-nav" className="main-nav">
                                <ul className="nav main-menu visible-md-block visible-lg-block">
                                    <li>
                                        <NavLink to="/#section-about">
                                            About
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/#section-events">
                                            Events
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/#section-contact">
                                            Contact
                                        </NavLink>
                                    </li>
                                    {token && (
                                        <li>
                                            <NavLink to="/events">
                                                Bookings
                                            </NavLink>
                                        </li>
                                    )}
                                    {!token && (
                                        <li className="no-show-link">
                                            <NavLink to="/login">Login</NavLink>
                                        </li>
                                    )}
                                    {token && (
                                        <li className="no-show-link">
                                            <a
                                                href="/#"
                                                className="no-style-button mobile-button-styling"
                                                onClick={handleLogout}
                                            >
                                                Logout
                                            </a>
                                        </li>
                                    )}
                                </ul>
                                <span className="mt-sidebar-toggle-icon hidden-md hidden-lg">
                                    <i className="fa fa-bars"></i>
                                </span>
                            </nav>
                            <div className="user-login-icon">
                                {!token && (
                                    <a href="/login">
                                        {' '}
                                        <button
                                            id="contact_send"
                                            type="submit"
                                            className="btn btn-slide"
                                            data-style="slide-up"
                                        >
                                            Login
                                        </button>{' '}
                                    </a>
                                )}
                                {token && (
                                    <button
                                        className="btn btn-slide"
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </button>
                                )}
                            </div>
                        </header>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
